import React, { FC, useEffect, useState } from 'react'
import PlusIcon from 'src/components/icons/PlusIcon'
import { TailwindColorConfig } from 'tailwindcss/tailwind-config'
import styled from 'styled-components'
import NewsTileContainer from 'src/components/news/list/NewsTileContainer'
import tw from 'twin.macro'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from 'src/../tailwind.config'
import toShortNewsDate from 'src/helpers/news'
import { Link } from 'gatsby-plugin-react-i18next'
import { isBrowser } from 'src/helpers/layout'
import ellipsize from 'src/helpers/ellipsize'

const tailwind = resolveConfig(tailwindConfig)

interface INewsItemTileProps {
  date: string
  title: string
  slug: string
  className?: string
  locale: string
}

const colors = resolveConfig(tailwindConfig).theme
  .colors as TailwindColorConfig & { [key: string]: string }

const boxPadding = tw`p-4 sm:p-7 md:p-9 lg:p-7`

const NewsTileDate = styled.div`
  ${tw`pl-4 sm:pl-7 md:pl-9 lg:pl-7 flex items-center lg:block text-white text-4xl sm:text-5xl font-extralight sm:font-thin`}
  word-spacing: 0.1em;
  @media (min-width: ${tailwind.theme.screens.lg}) {
    padding-top: 8.33%;
  }
`

const NewsPaddedBox = styled.div`
  ${boxPadding}
  ${tw`pt-0 sm:pt-0`}
`

const NewsItemTile: FC<INewsItemTileProps> = ({
  date,
  title,
  slug,
  className,
  locale,
}) => {
  const calculateMaxTitleLength = (): number => {
    if (!isBrowser) return 100
    const width = window.innerWidth
    if (width < 400) return 80
    if (width < 500) return 120
    if (width < 640) return 150
    if (width < 768) return 120
    if (width < 1024) return 55
    if (width < 1280) return 80
    if (width < 1536) return 55
    return 80
  }

  const [maxTitleLength, setMaxTitleLength] = useState(
    calculateMaxTitleLength()
  )

  useEffect(() => {
    const adjustTitleLength = () => setMaxTitleLength(calculateMaxTitleLength())
    window.addEventListener('resize', adjustTitleLength)
    return () => window.removeEventListener('resize', adjustTitleLength)
  })

  return (
    <NewsTileContainer
      className={`bg-grayBlue lg:h-full text-left${
        className ? ` ${className}` : ''
      }`}
    >
      <Link to={`/news/${slug}/`} className="block">
        <NewsTileDate className="absolute top-0 bottom-1/2 inset-x-0">
          {toShortNewsDate(date, locale)}
        </NewsTileDate>
        <NewsPaddedBox className="absolute top-1/2 bottom-0 inset-x-0 text-brightYellow text-lg sm:text-xl font-medium leading-tight mr-10 overflow-hidden">
          {ellipsize(title, maxTitleLength)}
        </NewsPaddedBox>
        <NewsPaddedBox className="absolute bottom-0 right-0">
          <PlusIcon
            width="2px"
            color={colors.white}
            className="w-7 sm:w-8 h-7 sm:h-8"
          />
        </NewsPaddedBox>
      </Link>
    </NewsTileContainer>
  )
}

export default NewsItemTile
