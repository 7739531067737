// i18next-extract-mark-ns-start news

import React, { FC } from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import NewsGrid from 'src/components/news/list/NewsGrid'
import { INewsItemHeader } from 'src/components/news/list/news-list-types'
import SemiRoundedButton, {
  ButtonSizes,
} from 'src/components/ui/SemiRoundedButton'
import create from 'zustand'
import shallow from 'zustand/shallow'
import ThinHeader from 'src/components/layout/ThinHeader'

interface INewsListProps {
  items: INewsItemHeader[]
}

interface VisibleCountState {
  visibleCount: number
  setVisibleCount: (value: number) => void
}

const minVisibleNewsCount = 4
const visibleNewsCountStep = 2

const useVisibleCountStore = create<VisibleCountState>((set) => ({
  visibleCount: minVisibleNewsCount,
  setVisibleCount: (value) => set(() => ({ visibleCount: value })),
}))

const NewsList: FC<INewsListProps> = ({ items }) => {
  const { t } = useTranslation('news')
  const { visibleCount, setVisibleCount } = useVisibleCountStore(
    (state) => ({
      visibleCount: state.visibleCount,
      setVisibleCount: state.setVisibleCount,
    }),
    shallow
  )
  const showMoreButton = visibleCount < items.length
  const showLessButton = visibleCount > minVisibleNewsCount

  const showMore = () =>
    setVisibleCount(Math.min(visibleCount + visibleNewsCountStep, items.length))

  const showLess = () =>
    setVisibleCount(
      Math.max(visibleCount - visibleNewsCountStep, minVisibleNewsCount)
    )

  return (
    <>
      <ThinHeader className="text-center">{t('Latest IFAB News')}</ThinHeader>
      <NewsGrid items={items} maxVisibleCount={visibleCount} />
      <div className="mt-12 mb-16 flex justify-center">
        {showLessButton && (
          <SemiRoundedButton
            onClick={showLess}
            className="mx-1"
            size={ButtonSizes.medium}
          >
            {t('Show less')}
          </SemiRoundedButton>
        )}
        {showMoreButton && (
          <SemiRoundedButton
            onClick={showMore}
            className="mx-1"
            size={ButtonSizes.medium}
          >
            {t('Show more')}
          </SemiRoundedButton>
        )}
      </div>
    </>
  )
}

export default NewsList
