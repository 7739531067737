// i18next-extract-mark-ns-start news

import React, { useContext, useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import Layout from 'src/components/layout'
import { Helmet } from 'react-helmet'
import PageTitle from 'src/components/typography/PageTitle'
import ContentPageContainer from 'src/components/layout/ContentPageContainer'
import { I18nextContext, useTranslation } from 'gatsby-plugin-react-i18next'
import NewsList from 'src/components/news/list/NewsList'
import Section from 'src/components/typography/Section'
import FacebookNewsList from 'src/components/news/social/FacebookNewsList'
import TwitterNewsList from 'src/components/news/social/TwitterNewsList'
import fetchFacebookNews from 'src/services/FacebookNewsApi'
import { IFacebookNewsItemData } from 'src/components/news/social/FacebookNewsItem'
import fetchTwitterNews from 'src/services/TwitterNewsApi'
import { ISocialNewsItemContentData } from 'src/components/news/social/social-news-types'

const MAX_SOCIAL_NEWS_ITEMS = 3

const IndexPage = ({ data }) => {
  const { t } = useTranslation('news')
  const { language } = useContext(I18nextContext)

  const [twitterNews, setTwitterNews] = useState<ISocialNewsItemContentData[]>(
    []
  )
  const [facebookNews, setFacebookNews] = useState<IFacebookNewsItemData[]>([])

  useEffect(() => {
    let cancelNewsFetch = false

    fetchTwitterNews(MAX_SOCIAL_NEWS_ITEMS)
      .then((result) => {
        if (cancelNewsFetch) {
          return
        }
        const items = result.map((item) => ({
          id: item.id,
          date: item.createdAt,
          content: item.text,
        }))
        setTwitterNews(items)
      })
      .catch(() => {})

    fetchFacebookNews(MAX_SOCIAL_NEWS_ITEMS)
      .then((result) => {
        if (cancelNewsFetch) {
          return
        }
        const items = result.map((item) => ({
          id: item.id,
          date: item.createdTime,
          content: item.message,
          imageUrl: item.fullPicture,
        }))
        setFacebookNews(items)
      })
      .catch(() => {})

    return () => {
      cancelNewsFetch = true
    }
  }, [])

  const maxItemCount = Math.max(facebookNews.length, twitterNews.length)

  return (
    <>
      <Helmet>
        <title>{t('News')}</title>
      </Helmet>
      <Layout>
        <div className="min-h-screen bg-white text-deepDarkBlue">
          <ContentPageContainer>
            <Section>
              <PageTitle>
                <p>{t('News')}</p>
              </PageTitle>
              <NewsList
                items={data.newsEn.news.filter((news) => news.meta.publishedAt)}
              />
              {/* <div className="lg:flex">
                <TwitterNewsList
                  className="lg:flex-1 lg:mr-0.5 mb-12 lg:mb-0"
                  locale={language}
                  items={twitterNews}
                  minimumItems={maxItemCount}
                />
                <FacebookNewsList
                  className="lg:flex-1 lg:ml-0.5"
                  locale={language}
                  items={facebookNews}
                  minimumItems={maxItemCount}
                />
              </div> */}
            </Section>
          </ContentPageContainer>
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    newsEn: allDatoCmsNews(
      filter: { locale: { eq: $language }, title: { ne: null } }
      sort: { fields: date, order: DESC }
    ) {
      news: nodes {
        id
        date
        title
        meta {
          publishedAt
        }
        listImage {
          url
          path
          alt
        }
        slug
        locale
      }
    }
  }
`

export default IndexPage
