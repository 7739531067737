import React, { FC } from 'react'
import NewsTileContainer from 'src/components/news/list/NewsTileContainer'
import { Link } from 'gatsby-plugin-react-i18next'

interface IImageContainerProps {
  url: string
}

interface INewsImageTileProps extends IImageContainerProps {
  alt: string
  slug: string
  className?: string
}

const NewsImageTile: FC<INewsImageTileProps> = ({
  url,
  alt,
  slug,
  className,
}) => (
  <NewsTileContainer className={className}>
    <Link to={`/news/${slug}/`}>
      <div className="overflow-hidden absolute inset-0">
        <picture className="">
          <source
            media="(max-width: 639px)"
            srcSet={`
                ${url}?fm=webp&auto=format&q=90&w=607&fit=max 1x,
                ${url}?fm=webp&auto=format&q=90&w=1214&fit=max 2x,
                ${url}?fm=webp&auto=format&q=90&w=1821&fit=max 3x
              `}
          />
          <source
            media="(max-width: 767px)"
            srcSet={`
                ${url}?fm=webp&auto=format&q=90&w=576&fit=max 1x,
                ${url}?fm=webp&auto=format&q=90&w=1152&fit=max 2x,
                ${url}?fm=webp&auto=format&q=90&w=1728&fit=max 3x
              `}
          />
          <source
            media="(max-width: 1023px)"
            srcSet={`
                ${url}?fm=webp&auto=format&q=90&w=352&fit=max 1x,
                ${url}?fm=webp&auto=format&q=90&w=704&fit=max 2x,
                ${url}?fm=webp&auto=format&q=90&w=1056&fit=max 3x
              `}
          />
          <source
            media="(max-width: 1279px)"
            srcSet={`
                ${url}?fm=webp&auto=format&q=90&w=448&fit=max 1x,
                ${url}?fm=webp&auto=format&q=90&w=896&fit=max 2x,
                ${url}?fm=webp&auto=format&q=90&w=1344&fit=max 3x
              `}
          />
          <source
            media="(max-width: 1536px)"
            srcSet={`
                ${url}?fm=webp&auto=format&q=90&w=352&fit=max 1x,
                ${url}?fm=webp&auto=format&q=90&w=704&fit=max 2x,
                ${url}?fm=webp&auto=format&q=90&w=1056&fit=max 3x
              `}
          />
          <source
            srcSet={`
                ${url}?fm=webp&auto=format&q=90&w=432&fit=max 1x,
                ${url}?fm=webp&auto=format&q=90&w=864&fit=max 2x,
                ${url}?fm=webp&auto=format&q=90&w=1296&fit=max 3x
              `}
          />
          <img
            src={`${url}?fm=webp&auto=format&q=90&w=576&fit=max`}
            className="object-cover w-full h-full"
            alt={alt}
          />
        </picture>
      </div>
    </Link>
  </NewsTileContainer>
)

export default NewsImageTile
