import styled from 'styled-components'
import tw from 'twin.macro'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from 'src/../tailwind.config'

const tailwind = resolveConfig(tailwindConfig)

const NewsTileContainer = styled.div`
  ${tw`relative h-72`}
  &::before {
    @media (min-width: ${tailwind.theme.screens.lg}) {
      padding-bottom: 66.67%;
    }
    content: '';
    float: left;
  }
  &::after {
    display: table;
    content: '';
    clear: both;
  }
`

export default NewsTileContainer
