import axios from 'axios'

interface ITwitterNewsItemApiResponse {
  id: string
  createdAt: string
  author: string
  text: string
}

const fetchTwitterNews = (
  limit: number
): Promise<ITwitterNewsItemApiResponse[]> =>
  axios
    .get(`https://web2-backend.theifab.com/api/twitter/tweets?limit=${limit}`)
    .then((response) => response.data)

export default fetchTwitterNews
