import axios from 'axios'

export interface IFacebookNewsItemApiResponse {
  id: string
  createdTime: string
  message: string
  picture: string
  fullPicture: string
  permalinkUrl: string
}

const fetchFacebookNews = (
  limit: number
): Promise<IFacebookNewsItemApiResponse[]> =>
  axios
    .get(`https://web2-backend.theifab.com/api/facebook/posts?limit=${limit}`)
    .then((response) => response.data)

export default fetchFacebookNews
